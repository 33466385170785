<template>
    <BlockUI :blocked="loading" :fullScreen="true" style="position:absolute;top:50%;left:50%;">
        <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 3em;"></i>
    </BlockUI>
    <div class="p-grid" :style="{padding: '15px'}">
        <div class="p-col-12 p-md-4">

            <div class="p-field p-fluid">
                <span class="p-float-label">
                    <Calendar id="registration" :showIcon="true" v-model="data.registration" dateFormat="dd/mm/yy"
                              :yearNavigator="true" yearRange="2000:2050" :minDate="startDate" :maxDate="endDate"
                              :class="{'p-invalid': submitted && (!data.registration )}" class="p-inputtext-sm" @date-select="getMonthAndWeek"/>
                    <label for="registration">Fecha de registro</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.registration">La fecha de registro es
                    requerida</small>
            </div>
            <div class="p-field p-fluid">
                <span class="p-float-label">
                    <InputText id="sequential" v-model="data.sequential" class="p-inputtext-sm" name="sequential_number"
                               :class="{'p-invalid': submitted && (!data.sequential )}"/>
                    <label for="registration">Número de matrícula</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.sequential">El número de matrícula es
                    requerido</small>
            </div>

            <div class="p-field-checkbox p-fluid">
                <Checkbox id="student" name="student" :binary="true" v-model="data.is_holder_student"/>
                <label for="student">El titular es beneficiario?</label>
            </div>
        </div>
        <div class="p-col-12 p-md-4">
            <div class="p-field p-fluid">
                <span class="p-float-label">
                     <AutoComplete id="week" :dropdown="true" :multiple="false" field="week"
                                   v-model="data.week" :suggestions="weeks"
                                   :class="{'p-invalid': submitted && (!data.week )}"
                                   @complete="getCatalog($event, 'weeks', 'week')" class="p-inputtext-sm" disabled/>
                    <label for="week">Semana</label>
                    <small class="p-invalid" v-if="submitted && !data.week">La semana es requerida</small>
                </span>
            </div>
            <div class="p-field p-fluid">
                <span class="p-float-label">
                   <AutoComplete id="month" :dropdown="true" :multiple="false" field="month"
                                 v-model="data.month" :suggestions="months"
                                 :class="{'p-invalid': submitted && (!data.month )}"
                                 @complete="getCatalog($event, 'months', 'month')" class="p-inputtext-sm" disabled/>
                    <label for="month">Mes</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.month">El mes es requerido</small>
            </div>
            <div class="p-field  p-fluid">
                <span class="p-float-label">
                    <AutoComplete id="plan" :dropdown="true" :multiple="false" field="name"
                                  v-model="data.plan" :suggestions="plans"
                                  :class="{'p-invalid': submitted && (!data.plan )}"
                                  @item-select=" data.cost = data.plan.cost"
                                  @complete="getCatalog($event, 'plans', 'description')" class="p-inputtext-sm"/>
                    <label for="plan">Plan</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.plan">El plan es requerido</small>
            </div>

            <div class="p-field p-fluid">
                <span class="p-float-label">
                    <InputText id="cost" v-model="data.cost" class="p-inputtext-sm"
                               :class="{'p-invalid': submitted && (!data.cost )}" readonly/>
                    <label for="cost">Valor</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.cost">El costo es requerido</small>
            </div>
        </div>
        <div class="p-col-12 p-md-4">
            <div class="p-field p-fluid">
                <span class="p-float-label">
                    <AutoComplete id="orig" :dropdown="true" :multiple="false" field="description" v-model="data.registration_origin"
                                  :suggestions="origins"
                                  @complete="getCatalog($event, 'origins', 'description')"
                                  :class="{'p-invalid': submitted && (!data.registration_origin )}"/>

                    <label for="orig">Origen de matrícula</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.registration_origin">El origen de la matrícula es requerido</small>
            </div>

            <div class="p-field p-fluid">
                <span class="p-float-label">
                    <AutoComplete id="org" :dropdown="true" :multiple="false" field="description"
                                  v-model="data.organization" :suggestions="organizations"
                                  @complete="getCatalog($event, 'organizations', 'description')"
                                  :class="{'p-invalid': submitted && (!data.organization )}"/>

                    <label for="org">Organización</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.organization">La organización es requerida</small>
            </div>
            <div class="p-field p-fluid">
                <span class="p-float-label">
                    <AutoComplete id="fil" :dropdown="true" :multiple="false" field="name"
                                  v-model="data.subsidiary" :suggestions="subsidiaries"
                                  @complete="getCatalog($event, 'subsidiaries', 'name')"
                                  :class="{'p-invalid': submitted && (!data.subsidiary )}"/>
                    <label for="fil">Filial</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.subsidiary">La filial es requerida</small>
            </div>
            <div class="p-field  p-fluid">
                <span class="p-float-label">
                    <AutoComplete id="asc" :dropdown="true" :multiple="false" field="name"
                                  v-model="data.employee" :suggestions="employees"
                                  :class="{'p-invalid': submitted && (!data.employee )}"
                                  @complete="getCatalog($event, 'employees', 'name')" class="p-inputtext-sm" :disabled="!data.subsidiary"/>
                    <label for="asc">Asesor</label>
                </span>
                <small class="p-invalid" v-if="submitted && !data.employee">El asesor es requerido</small>
            </div>
        </div>

    </div>
</template>
<script>

    import moment from 'moment'
    import service from '../../service/enrollment.service'
    import catalogueService from '../../../core/service/catalog.service'
    import {mapState} from "vuex";

    export default {
        name: "Enroll",
        components: {},
        props: {
            holder: Object,
            enroll: Object,
        },
        data() {
            return {
                loading: false,
                data: {},
                submitted: false,
                employees: [],
                months: [],
                weeks: [],
                subsidiaries: [],
                companies: [],
                organizations: [],
                payments: [],
                plans: [],
                origins: [],
                startDate: null,
                endDate: null,
            }
        },
        mounted() {
            const date = this.$filters.dateFormat(new Date());
            catalogueService.get(`weeks`, {date}).then(x => {
                this.startDate =  new Date(x.data[0].start)
                this.endDate = new Date(x.data[0].end)
            });
            if ( this.enroll) {
                this.data = this.enroll;
                this.data.is_holder_student = this.data.is_holder_student === 'y'  || this.data.is_holder_student === true;
                this.data.registration = new Date(moment(this.data.registration).format());
            }
            else  {
                this.data.registration = new Date();
                this.getMonthAndWeek(new Date());
            }
        },
        computed: {
            ...mapState('enroll', ['country']),
        },
        methods: {
            getMonthAndWeek(e) {
               const date = this.$filters.dateFormat(e);
               catalogueService.get(`weeks`, {date}).then(x => {
                   if(x.data[0]) {
                       this.data.week = x.data[0];
                   }
               });
                catalogueService.get(`months`, {date}).then(x => {
                    if(x.data[0]) {
                        this.data.month = x.data[0];
                    }
                });
            },
            formatData() {
                const params = {...this.data};
                params.subsidiary_id = params.subsidiary.id;
                params.registration_origin_id = params.registration_origin.id;
                params.organization_id = params.organization.id;
                params.plan_id = params.plan.id;
                params.employee_id = params.employee.id;
                params.holder_id = this.holder.id;
                params.registration = moment(this.data.registration).format("YYYY-MM-DD");
                params.week_id = params.week.id;
                params.month_id = params.month.id;
                params.is_holder_student = params.is_holder_student ? 'y': 'n';
                delete params.balance;
                return params;

            },
            save() {
                this.submitted = true;
                if (!this.data.subsidiary || !this.data.registration_origin || !this.data.organization ||
                    !this.data.plan || !this.data.employee || !this.data.registration ||
                    !this.data.sequential || !this.data.month || !this.data.week || !this.data.cost) {
                    this.$toast.add({
                        severity: 'warn',
                        summary: 'Campos requeridos',
                        detail: 'Por favor verifique la información',
                        life: this.$utils.toastLifeTime()
                    });
                    return null;
                }
                const params = this.formatData();
                return new Promise((resolve, reject) => {
                    this.loading = true;
                    service.save(params, params.id).then((x) => {
                        const response = x.data;
                        this.loading = false;
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Registro guardado',
                            detail: response.message,
                            life: this.$utils.toastLifeTime()
                        });
                        resolve();
                    }).catch((x) => {
                        this.loading = false;
                        const message = x.response.data;
                        let error = '';
                        Object.keys(message).forEach(key => {
                            error += error + message[key]   ? message[key] + '\n ' : '';
                        });
                        this.$toast.add({
                            severity: 'error',
                            summary: 'Error al registrar',
                            detail: error,
                            life: this.$utils.toastLifeTime()
                        });
                        reject();
                    });
                })
            },
            getCatalog(event, route, field) {
                const originalRoute = route;
                route = route === 'origins' ? 'registration/origins' : route;
                route = route === 'plans' ? `countries/${this.country.id}/plans` : route;
                route = route === 'employees' ? `subsidiaries/${this.data.subsidiary.id}/employees` : route;
                catalogueService.getCatalog(event, route, field).then(x => {
                    this[originalRoute] = x;
                }).catch(() => {
                    this[originalRoute] = [];
                });
            },

        }
    }
</script>
<style lang="scss" scoped>
    .p-progressbar {
        height: 1px;
    }

    .p-inputtext {
        width: 100%;
    }

    .p-autocomplete {
        width: 100%;
    }

    .p-fieldset {
        margin-top: 15px;
    }

    .p-field {
        padding-top: 15px;
    }

    .p-field-checkbox {
        margin-top: 30px;
    }

    .p-toolbar {
        margin-top: 10px;
        background-color: white;
    }

    .p-toolbar-group-right {
        width: 100%;
    }

    .p-calendar {
        height: 30px !important;
    }

    .p-autocomplete {
        height: 30px !important;
    }

</style>
